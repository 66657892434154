<template>
  <div>
    <nav
      class="px-0 pt-4 d-flex justify-content-center nav-link text-light bg-selection-lite shadow"
    >
      <ul
        class="nav text-nowrap flex-nowrap pb-4"
        data-aos="flip-right"
        style="overflow-x: auto"
      >
        <li class="nav-item p-1">
          <router-link
            :class="$route.fullPath === '/' ? 'active' : ''"
            class="link-style m-3 p-1"
            text-purple
            to="/"
            ><i class="bi bi-house-fill"></i>
            Accueil
          </router-link>
        </li>
        <li class="nav-item p-1">
          <router-link
            :class="$route.fullPath === '/my-blog' ? 'active' : ''"
            class="link-style m-3 p-1"
            to="/my-blog"
            ><i class="bi bi-substack"></i> Blog & Tutos
          </router-link>
        </li>
        <li class="nav-item p-1">
          <router-link
            :class="$route.fullPath === '/projects' ? 'active' : ''"
            class="link-style m-3 p-1"
            to="/projects"
            ><i class="bi bi-kanban-fill"></i> Projects
          </router-link>
        </li>
        <!-- <li class="nav-item p-1">
          <router-link
            :class="$route.fullPath === '/experiences&degrees' ? 'active' : ''"
            class="link-style m-3 p-1"
            to="/experiences&degrees"
            ><i class="bi bi-file-earmark-text-fill"></i> XP & Certif'
          </router-link>
        </li> -->
        <li class="nav-item p-1">
          <router-link
            :class="$route.fullPath === '/sh' ? 'active' : ''"
            class="link-style m-3 p-1"
            to="/sh"
            ><i class="bi bi-terminal"></i>
            terminal
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="banner" v-if="shouldShowPopup">
      <div
        class="banner-content bg-white rounded-lg shadow-lg p-6 fixed bottom-0 left-0 right-0"
      >
        <p class="font-bold mb-4">Un peu de statistiques et des cookies 🍪</p>
        <small class="text-muted mb-4"
          >En appuyant sur le bouton "Accepter", vous donnez votre bénédiction
          pour que je déploie mon armée de Gremlins analytiques afin de compter
          les pèlerins qui se perdent sur ce site web. </small
        ><br />
        <small>*Aucune donnée personnelle n'est collectée.</small>
        <br />
        <div class="d-flex justify-content-between">
          <button
            class="btn btn-secondary py-2 px-4 mt-3 rounded-full text-white font-bold hover:bg-pink-600 transition-colors duration-300"
            @click="refuseAnalytics"
          >
            Refuser
          </button>
          <button
            class="btn btn-pink py-2 px-4 mt-3 rounded-full text-white font-bold hover:bg-pink-600 transition-colors duration-300"
            @click="acceptAnalytics"
          >
            Accepter
          </button>
        </div>
      </div>
    </div>
    <div v-if="this.$route.matched.length > 0" class="router-view">
      <router-view />
    </div>
    <div v-else>
      <NotFoundView></NotFoundView>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import NotFoundView from "@/views/NotFoundView";
import { useHead, useSeoMeta } from "@unhead/vue";

export default {
  name: "App",
  components: { NotFoundView },
  setup() {
    const store = useStore();
    const showPopup = ref(true);
    const title = ref("Ben | Concepteur & Développeur d'applications web");
    const description = ref(
      `Créateur et bidouilleur numérique passionné, concepteur et développeur 
      d'application web. Découvrez mon parcours, mes projets, mon blog, mes compétences`
    );

    onBeforeMount(() => {
      // execution des requêtes au lancement de la page HomeView (avant le montage)
      store.dispatch("getAboutContent");
      store.dispatch("getAboutContent2");
      store.dispatch("getExperiencesContent");
      store.dispatch("getDegreesContent");
      store.dispatch("getProjectsContent");
      store.dispatch("getBlogContent");
      store.dispatch("getHeaderContent");
      store.dispatch("getFollowersContent");
    });

    const shouldShowPopup = computed(
      () => document.cookie.indexOf("analytics=true") === -1 && showPopup.value
    );

    const acceptAnalytics = () => {
      document.cookie = "analytics=true";
      showPopup.value = false;
    };

    const refuseAnalytics = () => {
      // redirect to google.com
      window.location.href = "https://duckduckgo.com";
    };

    useHead({
      link: [
        {
          rel: "canonical",
          type: "url",
          href: "https://benoitpetit.dev/index.html",
        },
      ],
      meta: [
        {
          name: "keywords",
          content:
            "Benoit Petit, Portfolio, Développeur web, Golang, Node.js, Vue.js, ben, benoit, petit, développeur, concepteur, web, dev, programmation, blog, benoitpetit.dev",
        },
      ],
    });

    useSeoMeta({
      title: title,
      author: "Benoit Petit",
      description: description,
      searchDescription: "Ben | Concepteur Développeur d'Applications web",
      ogUrl: "https://benoitpetit.dev",
      ogDescription: description,
      ogLocale: "fr_FR",
      ogLocaleAlternate: "en_US",
      ogTitle: title,
      ogImage: "/assets/card.png",
      ogType: "website",
      ogImageUrl: "/assets/card.png",
      ogSiteName: title,
      twitterCard: "/assets/card.png",
      twitterSite: "@codingben_",
      twitterDescription: description,
      twitterTitle: title,
      twitterImage: "/assets/card.png",
      twitterImageAlt: "Ben | Concepteur Développeur d'Applications web",
      robots: "index, follow",
    });

    return {
      NotFoundView,
      showPopup,
      shouldShowPopup,
      acceptAnalytics,
      refuseAnalytics,
    };
  },
};
</script>

<style>
.nav-link {
  z-index: 7;
  padding: 0;
}

.nav-item {
  color: #fff;
  font-size: 16px;
}

nav {
  padding: 0;
}

.router-view {
  width: 100%;
  margin: 0 auto;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  color: #000;
}

.banner-content {
  margin: 0 auto;
  left: 0;
  right: 0;
  max-width: 30%;
  padding: 1.5rem;
  margin: auto;
  bottom: 0;
  position: fixed;
}

.btn-pink {
  background-color: #ff6b6b;
}

.btn-pink:hover {
  background-color: #ff4757;
}

@media screen and (max-width: 600px) {
  .banner-content {
    max-width: 90%;
  }
}
</style>
