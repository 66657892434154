import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
// import ExperiencesView from "@/views/ExperiencesView";
import ProjectsView from "@/views/ProjectsView";
import BlogView from "@/views/BlogView";
import PostView from "@/views/PostView";
import NotFoundView from "@/views/NotFoundView";
import ShView from "@/views/ShView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: "/experiences&degrees",
  //   name: "experiences&degrees",
  //   component: ExperiencesView,
  // },
  {
    path: "/projects",
    name: "projects",
    component: ProjectsView,
  },
  {
    path: "/my-blog",
    name: "my-blog",
    component: BlogView,
  },
  {
    path: "/my-blog/:id",
    name: "my-blog-post",
    component: PostView,
  },
  {
    path: "/sh",
    name: "sh",
    component: ShView,
  },
  {
    path: "/*",
    redirect: "Notfound",
  },
  {
    path: "/404",
    name: "Notfound",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
