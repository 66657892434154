<template>
  <div class="home container" data-aos="fade-down-zoom">
    <HeaderComponent></HeaderComponent>
    <AboutComponent></AboutComponent>
    <div v-if="quote" class="quote">
      <p>
        <strong>{{ quote.character_name }}</strong>
      </p>
      <p class="text-muted">
        <em>{{ quote.quote }}</em>
      </p>
      <span style="font-style: italic"
        >In the episode : {{ quote.episode_name }}</span
      >
      <p style="text-align: right">
        <a
          class="link-style"
          href="https://inside-verse-api.com"
          target="_blank"
        >
          <span>Inside verse API</span>
        </a>
      </p>
    </div>
    <Footer-component></Footer-component>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import HeaderComponent from "@/components/HeaderComponent";
import AboutComponent from "@/components/AboutComponent";
import FooterComponent from "@/components/FooterComponent";

import twitter from "../assets/images/socials/twitter.webp";
import linkedin from "../assets/images/socials/linkedin.webp";
import github from "../assets/images/socials/github.webp";
import mastodon from "../assets/images/socials/mastodon.webp";

export default {
  name: "HomeView",
  components: {
    FooterComponent,
    AboutComponent,
    HeaderComponent,
  },
  setup() {
    const twitterRef = ref(twitter);
    const linkedinRef = ref(linkedin);
    const githubRef = ref(github);
    const mastodonRef = ref(mastodon);
    const quote = ref(null);
    const characterName = ref("");

    const fetchQuote = () => {
      fetch("https://inside-verse-api.com/quotes/random")
        .then((response) => response.json())
        .then((data) => {
          quote.value = data;
        })
        .catch((error) => {
          console.error("Error fetching quote:", error);
        });
    };

    onMounted(fetchQuote);

    return {
      twitter: twitterRef,
      linkedin: linkedinRef,
      github: githubRef,
      mastodon: mastodonRef,
      quote,
      characterName,
    };
  },
};
</script>

<style>
.quote {
  border: 1px solid var(--current_line);
  padding: 10px;
  background-color: var(--background);
  border-radius: 5px;
  width: 40%;
  margin: auto;
  margin-top: 60px;
  color: #adadad;
}

@media screen and (max-width: 600px) {
  .quote {
    width: 100%;
  }
}

.quote p {
  font-size: 16px;
  margin-bottom: 5px;
}

.quote small:first-child {
  font-size: 12px;
  color: #cfcfcf;
}
</style>
