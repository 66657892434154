import { createStore } from "vuex";

export default createStore({
  state: {
    projectsContent: "",
    aboutContent: "",
    aboutContent2: "",
    experiencesContent: "",
    degreesContent: "",
    blogContent: [],
    headerContent: "",
    analytics: false,
    followersContent: "",
  },
  getters: {
    projectsContent: (state) => {
      return state.projectsContent;
    },
    aboutContent: (state) => {
      return state.aboutContent;
    },
    aboutContent2: (state) => {
      return state.aboutContent2;
    },
    experiencesContent: (state) => {
      return state.experiencesContent;
    },
    degreesContent: (state) => {
      return state.degreesContent;
    },
    blogContent: (state) => {
      return state.blogContent;
    },
    headerContent: (state) => {
      return state.headerContent;
    },
    followersContent: (state) => {
      return state.followersContent;
    },
  },
  mutations: {
    SET_PROJECTS(state, projectsContent) {
      state.projectsContent = projectsContent;
    },
    SET_ABOUT(state, aboutContent) {
      state.aboutContent = aboutContent;
    },
    SET_ABOUT2(state, aboutContent2) {
      state.aboutContent2 = aboutContent2;
    },
    SET_EXPERIENCE(state, experiencesContent) {
      state.experiencesContent = experiencesContent;
    },
    SET_DEGREES(state, degreesContent) {
      state.degreesContent = degreesContent;
    },
    SET_BLOG(state, blogContent) {
      state.blogContent = blogContent;
    },
    SET_HEADER(state, headerContent) {
      state.headerContent = headerContent;
    },
    setAnalytics(state, value) {
      state.analytics = value;
    },
    SET_FOLLOWERS_CONTENT(state, value) {
      state.followersContent = value;
    },
  },
  actions: {
    async getProjectsContent() {
      if (!this.state.projectsContent.length > 0) {
        try {
          const response = await fetch("/.netlify/functions/projectsContent");
          const data = await response.json();
          this.commit("SET_PROJECTS", data.projectsContent.results.reverse());
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getFollowersContent() {
      if (!this.state.followersContent.length > 0) {
        try {
          const response = await fetch("/.netlify/functions/followersContent");
          const data = await response.json();
          this.commit("SET_FOLLOWERS_CONTENT", data.followersContent);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getAboutContent2() {
      if (!this.state.aboutContent2.length > 0) {
        try {
          const response = await fetch("/.netlify/functions/aboutContent2");
          const data = await response.json();
          this.commit("SET_ABOUT2", data.aboutContent2.results);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getAboutContent() {
      if (!this.state.aboutContent.length > 0) {
        try {
          const response = await fetch("/.netlify/functions/aboutContent1");
          const data = await response.json();
          this.commit("SET_ABOUT", data.aboutContent1.results);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getExperiencesContent() {
      if (!this.state.experiencesContent.length > 0) {
        try {
          const response = await fetch(
            "/.netlify/functions/experiencesContent"
          );
          const data = await response.json();
          this.commit(
            "SET_EXPERIENCE",
            data.experiencesContent.results.reverse()
          );
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getDegreesContent() {
      if (!this.state.degreesContent.length > 0) {
        try {
          const response = await fetch("/.netlify/functions/degreesContent");
          const data = await response.json();
          this.commit("SET_DEGREES", data.degreesContent.results.reverse());
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getHeaderContent() {
      if (!this.state.headerContent.length > 0) {
        try {
          const response = await fetch("/.netlify/functions/headerContent");
          const data = await response.json();
          this.commit("SET_HEADER", data.headerContent.results);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getBlogContent({ state, commit }) {
      if (!state.blogContent.length > 0) {
        try {
          const response = await fetch(process.env.VUE_APP_DEV_TO_BLOG);
          const data = await response.json();
          commit("SET_BLOG", data);
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
  modules: {},
});
