<template>
  <div class="projects">
    <div class="second-title content-background-title-dotted py-4 my-3">
      <h2 class="text-center title-hero-page">
        Projets <i class="bi bi-kanban-fill"></i>
      </h2>
      <p class="text-center text-muted pt-2">
        Une liste de projets indéfiniment extensible, <br />
        je continuerai demain !
      </p>
    </div>
    <div class="content-background-dotted p-2 tablflow shadow">
      <table
        v-if="projectsContent && projectsContent.length > 0"
        class="tb-size table table-sm text-secondary"
      >
        <thead>
          <tr class="text-secondary border-secondary">
            <th class="text-purple" scope="col">N°</th>
            <th class="text-purple" scope="col">Projet</th>
            <th class="text-purple" scope="col">Description</th>
            <th class="text-purple" scope="col">Stack</th>
            <th class="text-purple" scope="col">Progression</th>
            <th class="text-purple" scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in projectsContent"
            :key="item.id"
            class="text-secondary border-secondary"
          >
            <td>#00{{ projectsContent.length - index }}</td>
            <td style="white-space: nowrap">
              <a
                :href="item.field_334715"
                target="_blank"
                rel="noopener noreferrer"
                class="link-style"
              >
                {{ item.field_334711 }}
              </a>
            </td>
            <td>{{ item.field_334714 }}</td>
            <td>
              <small class="badge badge-stack">{{ item.field_334713 }}</small>
              <div class="bdg">
                <span
                  v-for="badge in item.field_336094"
                  :key="badge.id"
                  class="badge badge-pill badge-stack m-1"
                >
                  {{ badge.value }}
                </span>
              </div>
            </td>
            <td>
              <div
                class="progress"
                style="background-color: #bd93f907; height: 20px"
              >
                <div
                  class="progress-bar"
                  style="background-color: #44475a"
                  role="progressbar"
                  :style="{ width: item.field_1499401 + '%' }"
                  :aria-valuenow="item.field_1499401"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ item.field_1499401 }}%
                </div>
              </div>
            </td>
            <td>
              {{ new Date(item.field_1496681).toLocaleDateString("fr-FR") }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="smooth">
        <p class="text-light text-center mt-3">
          Les données sont en cours de chargement ...
        </p>
        <p class="text-light text-center">
          Si rien ne s'affiche actualiser la page 👍️
        </p>
        <div class="d-flex justify-content-center mt-3">
          <img :src="this.loadingFile" alt="loading" height="70" width="70" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import loadingFile from "@/assets/images/loading.gif";

export default {
  name: "ProjectsComponent",
  data() {
    return {
      loadingFile: loadingFile,
    };
  },
  computed: mapState({
    projectsContent: (state) => state.projectsContent,
  }),
};
</script>

<style scoped>
.tablflow {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 1.8rem;
  max-height: 100rem;
  padding: 20px;
}
@media screen and (max-width: 600px) {
  .tb-size {
    min-width: 130vh;
  }
}
.footer-table {
  min-width: 130vh;
  margin-bottom: 0.5rem;
}
</style>
