<template>
  <div data-aos="fade-zoom" data-aos-delay="100">
    <div v-if="postContent.error">
      <NotFoundView></NotFoundView>
    </div>
    <div v-else>
      <div
        v-if="postContent.id == idPost"
        class="post container px-2 mt-3 mx-auto top"
        style="overflow: hidden"
      >
        <div
          class="content-background-dotted"
          v-if="postContent.user.username === 'benoitpetit'"
        >
          <!--        <pre>{{ postContent.user.username }}</pre>-->
          <div class="p-2 d-flex justify-content-between">
            <div>
              <router-link
                :to="`/my-blog`"
                class="link-style"
                style="text-decoration: none"
                >👈️ retour</router-link
              >
            </div>
            <div>
              <a
                class="m-2 btn btn-dark btn-sm"
                href="https://dev.to/benoitpetit#user-follow-butt"
                target="_blank"
              >
                Follow
                <img :src="devLogo" height="25" width="25" alt="dev.to logo" />
              </a>
              <a
                class="btn btn-dark btn-sm"
                href="https://benoitpetit.substack.com/subscribe"
                target="_blank"
              >
                Follow
                <img
                  :src="substackLogo"
                  height="25"
                  width="25"
                  alt="dev.to logo"
                  target="_blank"
                />
              </a>
            </div>
          </div>
          <img :src="postContent.cover_image" class="cover-img" />
          <!--          <pre>{{ postContent }}</pre>-->
          <a
            target="_blank"
            :href="`https://dev.to/${postContent.user.username}`"
            style="text-decoration: none"
          >
            <div class="d-flex justify-content-center mt-3 align-items-center">
              <div class="m-2">
                <img
                  class="rounded-circle"
                  :src="postContent.user.profile_image"
                  height="30"
                  alt="profil pic"
                />
              </div>
              <h5 class="mt-2 link-style">{{ postContent.user.name }}</h5>
            </div>
          </a>
          <!--          <pre class="p-3">{{ postContent.user.profile_image }}</pre>-->
          <div class="d-flex justify-content-center">
            <h1 class="title-hero-page-post text-center">
              {{ postContent.title }}
            </h1>
          </div>
          <div class="d-flex justify-content-center">
            <span v-for="tag in postContent.tags" :key="tag">
              <span class="badge bg-purple mx-2 text-dark">{{ tag }}</span>
            </span>
          </div>
          <small class="d-flex justify-content-center my-2 text-muted">{{
            new Date(postContent.published_timestamp).toLocaleDateString(
              "fr-FR",
              {
                day: "numeric",
                month: "long",
                year: "numeric",
              }
            )
          }}</small>
          <div class="content-post-markdown">
            <Markdown
              class="p-3 rounded content-post"
              :source="postContent.body_markdown"
            ></Markdown>
            <hr class="w-100 mx-auto" />
            <h4 class="text-center">
              Abonnez-vous a ma newsletter Substack ❤️
            </h4>
            <p class="text-center m-auto">
              Pour recevoir les plus récents articles et actualités tech ! ✨
            </p>
            <div class="container d-flex justify-content-center">
              <iframe
                src="https://benoitpetit.substack.com/embed"
                width="55%"
                height="auto"
                style="border: 2px solid var(--pink); border-radius: 10px"
                frameborder="2"
                scrolling="no"
                class="my-3"
              ></iframe>
            </div>
          </div>
          <div class="read-dev m-2 d-flex justify-content-end">
            <a target="_blank" class="link-style" :href="postContent.url"
              >Lire sur dev.to
              <img :src="devLogo" height="25" width="25" alt="dev.to logo" />
            </a>
          </div>
          <div class="container">
            <h4>📚 Autres Articles</h4>
            <hr class="w-25 mx-left" />
          </div>
          <ul class="list-group">
            <li
              v-for="post in blogContent"
              :key="post.id"
              class="list-group-item content-background"
            >
              <router-link
                :to="`/my-blog/${post.id}`"
                class="link-style"
                style="text-decoration: none"
              >
                👉 {{ post.title }}
              </router-link>
            </li>
          </ul>
          <!-- show ul list to post aleatoire with blogContent -->
        </div>
        <div v-else>
          <NotFoundView></NotFoundView>
        </div>
      </div>
      <div v-else class="smooth">
        <p class="text-light text-center mt-3">
          Les données sont en cours de chargement ...
        </p>
        <p class="text-light text-center">
          Si rien ne s'affiche actualiser la page 👍️
        </p>
        <div class="d-flex justify-content-center mt-3">
          <img :src="this.loadingFile" alt="loading" height="70" width="70" />
        </div>
      </div>
      <div class="container">
        <FooterComponent></FooterComponent>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import loadingFile from "../assets/images/loading.gif";
import NotFoundView from "@/components/NotFoundComponent";
import FooterComponent from "@/components/FooterComponent";
import Markdown from "vue3-markdown-it";
import { useSeoMeta } from "@unhead/vue";
import devLogo from "../assets/images/dev-logo.webp";
import substackLogo from "../assets/images/substack_logo.webp";

export default {
  name: "PostView",
  components: { FooterComponent, NotFoundView, Markdown },
  setup() {
    const route = useRoute();
    const store = useStore();
    const idPost = ref(route.params.id);
    const postContent = ref([null]);
    const loadingFileRef = ref(loadingFile);
    const hoverTimeout = ref(null);
    const blogContent = computed(() => store.state.blogContent);

    const fetchPost = async () => {
      try {
        const response = await fetch(
          process.env.VUE_APP_DEV_TO_POST + idPost.value
        );
        const data = await response.json();
        postContent.value = data;
      } catch (error) {
        console.error(error);
      }
    };

    const isBenoitPetit = computed(() =>
      postContent.value && postContent.value.user
        ? postContent.value.user.username === "benoitpetit"
        : true
    );

    const pageTitle = computed(() =>
      isBenoitPetit.value && postContent.value
        ? `Ben | ${
            postContent.value.title ? postContent.value.title : "Blog & Tutos"
          }`
        : "Ben | Blog & Tutos"
    );
    const pageDescription = computed(() =>
      isBenoitPetit.value && postContent.value
        ? postContent.value.description
        : undefined
    );
    const pageImage = computed(() =>
      isBenoitPetit.value && postContent.value
        ? postContent.value.social_image
        : undefined
    );

    const followers = computed(() => store.state.followersContent);

    useSeoMeta({
      title: pageTitle,
      description: pageDescription,
      ogSiteName: pageTitle,
      ogTitle: pageTitle,
      ogDescription: pageDescription,
      ogUrl: "https://benoitpetit.dev/my-blog/" + idPost.value,
      ogImage: pageImage,
      ogType: "article",
      twitterCard: pageImage,
      twitterDescription: pageDescription,
      twitterTitle: pageTitle,
      twitterImage: pageImage,
      twitterImageAlt: pageTitle,
    });

    onMounted(fetchPost);

    watch(
      () => route.params.id,
      (newId, oldId) => {
        if (newId !== oldId && newId !== undefined) {
          idPost.value = newId;
          fetchPost();
        }
      }
    );

    return {
      idPost,
      postContent,
      loadingFile: loadingFileRef,
      hoverTimeout,
      blogContent,
      fetchPost,
      followers,
      devLogo,
      substackLogo,
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Wellfleet&display=swap");

.hero-post {
  height: 200px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}
.title-hero-page-post {
  max-width: 50%;
}

.hero-post::before {
  background-color: red;
}

.cover-img {
  display: block;
  margin: 0 auto;
  max-width: 50%;
  height: auto;
  border-radius: 10px;
}

.hero-post > h1 {
  color: var(--foreground);
  font-size: 3rem;
  font-weight: bold;
}

.content-post > h2,
h3,
h4 {
  /* change la police et plus gras */
  font-family: "Wellfleet", cursive;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--foreground);
}

.content-post {
  background-color: #2d2f3d;
  border-radius: 30px;
  max-width: 55%;
  margin: 0 auto;
}
.content-post-markdown {
  background-color: #2d2f3d;
  padding: 30px;
}

.title-post {
  color: var(--foreground);
  font-size: 1.5rem;
  font-family: "Rubik", sans-serif;
}

.content-post {
  color: #dfe1ec;
  font-size: 1rem;
}

.content-post a:not(:has(img)) {
  color: var(--foreground);
  text-decoration: none;
  background-image: linear-gradient(
    90deg,
    rgba(228, 139, 253, 0.38) 100%,
    var(--background) 0
  );
  background-position: 0 80%;
  background-repeat: repeat-x;
  background-size: 100% 23%;
  transition: background-size 0.3s;
  padding-left: 2px;
  padding-right: 2px;
  word-wrap: break-word; /* Add this line */
}

.content-post a:not(:has(img)):hover {
  color: var(--background);
  background-image: linear-gradient(
    90deg,
    var(--pink) 100%,
    var(--background) 0
  );
  background-size: 100% 100%;
}

.content-post img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  width: auto;
  height: auto;
  border-radius: 10px;
}

.content-post blockquote {
  padding: 20px 20px 8px 20px;
  font-style: italic;
  color: #a5a8bd;
  background-color: #262833;
  border-radius: 10px;
}

div.content-post-markdown > div > pre > code {
  background-color: #171920;
  border-radius: 10px;
  /* border-image: linear-gradient(90deg, var(--pink), var(--background)) 1; */
  padding: 2em;
  font-family: "Fira Code", monospace;
  overflow-x: auto;
  font-size: 0.9rem;
}

@media (max-width: 600px) {
  .content-post {
    max-width: 100%;
    margin: 0 auto;
  }
  .content-post img {
    max-width: 100%;
    margin: 0 auto;
  }
  .content-post-markdown {
    padding: 0px;
  }
  .cover-img {
    max-width: 100%;
    border-radius: 0;
  }
  .title-hero-page-post {
    max-width: 100%;
  }
  iframe {
    width: 100%;
  }
}
</style>
