<template>
  <div class="container mt-4">
    <div class="input-group position-relative d-flex justify-content-between">
      <a
        class="btn btn-dark btn-sm"
        href="https://dev.to/benoitpetit#user-follow-butt"
      >
        <img :src="devLogo" height="25" width="25" alt="dev.to logo" />
        {{ followers.content }} followers
      </a>
      <input
        type="text"
        class="form-control form-search-style"
        placeholder="Rechercher un article"
        v-model="search"
      />
      <span
        class="input-group-text bg-transparent text-light border-0 position-absolute end-0"
        v-if="search"
        @click="search = ''"
        style="cursor: pointer"
      >
        <i class="bi bi-x"></i>
      </span>
    </div>
    <hr style="color: var(--foreground)" />
    <div class="posts row mt-3" v-if="filteredBlogContent.length">
      <div
        class="col-xs-12 col-xl-3 p-0 mt-3"
        v-for="post in filteredBlogContent"
        :key="post.id"
      >
        <div class="card content-background-dotted m-2 p-0 shadow">
          <router-link
            :to="{
              name: 'my-blog-post',
              params: { id: post.id },
            }"
            style="font-size: 20px"
          >
            <img
              :src="post.cover_image"
              class="card-img-top"
              alt="cover post pic"
            />
          </router-link>
          <div class="card-body card-content-post">
            <router-link
              :to="{ name: 'my-blog-post', params: { id: post.id } }"
              class="link-style"
              style="font-size: 20px"
              >{{ post.title }}</router-link
            >
            <p class="card-text content-mono mb-0">{{ post.description }}</p>
            <p class="text-purple mt-0">{{ post.tags }}</p>
            <!-- <router-link
              :to="{ name: 'my-blog-post', params: { id: post.id } }"
              class="btn btn-sm btn-pink"
              >Lire la suite</router-link
            > -->
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column align-items-center justify-content-center smooth"
    >
      <p class="text-pink text-center mt-3" style="font-size: 20px">
        Votre recherche n'a donné aucun résultat.
      </p>
      <!-- cliquer ici pour recharcher -->
      <a href="/my-blog" class="link-style text-center">
        cliquer ici pour recharcher
      </a>
      <div class="d-flex justify-content-center my-3">
        <img :src="loadingFile" alt="loading" height="300" width="300" />
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from "../components/FooterComponent.vue";
import loadingFile from "../assets/images/oops.gif";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useSeoMeta } from "@unhead/vue";
import devLogo from "../assets/images/dev-logo.webp";

export default {
  name: "BlogView",
  components: {
    FooterComponent,
  },
  setup() {
    const store = useStore();
    const search = ref("");
    const filteredBlogContent = computed(() =>
      store.state.blogContent
        .filter((post) =>
          post.title.toLowerCase().includes(search.value.toLowerCase())
        )
        .slice(0, 12)
    );
    const followers = computed(() => store.state.followersContent);
    const title = ref("Ben | Blog & Tutos");
    const description = ref(
      `Plongez dans mon espace personnel ici c'est un carrefour d'informations où je partage des astuces utiles et explore les tendances émergentes du monde numérique.`
    );
    useSeoMeta({
      title: title,
      description: description,
      ogTitle: title,
      ogDescription: description,
      ogUrl: "https://benoitpetit.dev/my-blog",
      ogType: "article",
      ogSiteName: title,
      twitterDescription: description,
      twitterTitle: title,
    });

    return {
      search,
      filteredBlogContent,
      loadingFile,
      followers,
      devLogo,
    };
  },
};
</script>

<style scoped>
.card {
  overflow: hidden;
}
.card-img-top {
  height: 100px;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
}

.card-img-top:hover {
  transform: scale(1.1);
}

.form-search-style {
  background-color: #262b30;
  border-color: var(--pink);
  color: #cfcfcf;
  max-width: 25%;
  /* place sur la droite */
  margin-left: auto;
}

@media screen and (max-width: 600px) {
  .form-search-style {
    max-width: 100%;
  }
}

.form-search-style:focus,
.form-search-style:active,
.form-search-style:hover {
  background-color: #191c1f;
  border-color: var(--pink);
  color: #cfcfcf;
  box-shadow: 0 0 0 0.2rem rgba(174, 37, 202, 0.25);
}

.form-search-style:disabled {
  background-color: #262b30;
  border-color: var(--pink);
  color: #cfcfcf;
}

.input-group-text {
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}
@media (max-width: 576px) {
  .input-group {
    display: flex;
    flex-direction: column;
  }
  .input-group > input {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
