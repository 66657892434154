<template #bar>
  <div>
    <div class="second-title content-background-title-dotted py-4 my-3">
      <h2 class="display-8 text-center title-hero-page">
        Terminal <i class="bi bi-terminal"></i>
      </h2>
      <p class="text-center text-muted">Explorer le site via de ce terminal</p>
    </div>
    <vue-command
      style="height: 500px; box-shadow: black 0px 0px 8px 0px"
      :commands="commands"
      prompt="visitor@benoitpetit.dev:~$"
      help-text="type help to see available commands"
      :show-help="true"
      :hide-bar="true"
      class="content-background-dotted"
    />
  </div>
</template>

<script lang="js">
import VueCommand, { createStdout, jsonFormatter, textFormatter } from "vue-command";
import "vue-command/dist/vue-command.css";
import $router from "@/router";
import picture from "../assets/images/profil/pic.jpg";
import chuck from "./GetblogTeminalComponent.vue"

function calculateAge(birthDate) {
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const m = today.getMonth() - birth.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
    age--;
  }
  return age;
}

export default {
  name: "TerminalComponent",
  data: () => ({
    picture: picture,
    commands: {
      cat: () => createStdout("🐈‍⬛ Miaw !"),
      blog: () => chuck,
      secret: () => {
          open("https://v1.windows93.net/");
          return createStdout("You found the secret command, congrats!");
      },
      social: () => createStdout(textFormatter(`
      follow me on social networks 😎
      --------------------

      > <a target="_blank" href='https://www.benoitpetit.dev'>Website</a>
      > <a target="_blank" href='https://www.linkedin.com/in/benoit-petit-110'>Linkedin</a>
      > <a target="_blank" href='https://www.twitter.com/codingben_'>Twitter</a>
      > <a target="_blank" href='https://github.com/benoitpetit'>Github</a>
      > <a target="_blank" href='https://gitlab.com/petitbenoit'>Gitlab</a>
      > <a target="_blank" href='https://www.instagram.com/bensmall404'>Instagram</a>

      `, { isHtml: true })),
      about: () => createStdout(jsonFormatter(
        {
          true: "Je code comme si ma vie en dépendait",
          name: "Benoit Petit",
          age: calculateAge("1988-03-12"),
          job: "Concepteur & Développeur d'Applications",
          location: "Lille, France",
          tech: "Node.js, Vue.js, Golang, Docker, Linux, Github",
        }
      )),
      clear: () => {
        location.reload();
      },
      help: () => createStdout(`
      available commands ✨
      --------------------

      > about => show about
      > blog => show latest blog posts
      > social => show social links
      > cat => show cat
      > help => show help
      > clear => clear terminal
      > exit => exit terminal

      latest features 🚀
      --------------------

      X contact => contact me on terminal
      X picture => show picture
      X chuck => show chuck norris joke
      > ... => more to come

      `),
      exit: () => {
        $router.push("/");
      }
    },
  }),
  components: {
    VueCommand,
  },
  watch: {
    commands: {
      handler() {
        this.$nextTick(() => {
          this.$refs.terminal.scrollIntoView();
        });
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
div,
textarea {
  height: 100%;
  margin: 0 auto;
}
</style>
