import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/app.css";

import "highlight.js/styles/atom-one-dark.css";

import AOS from "aos";
import "aos/dist/aos.css";

import { createHead } from "@unhead/vue";

AOS.init({
  // Global settings:
  disable: false,
  startEvent: "DOMContentLoaded",
  initClassName: "aos-init",
  animatedClassName: "aos-animate",
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120,
  delay: 0,
  duration: 400,
  easing: "ease",
  once: true,
  mirror: false,
  anchorPlacement: "top-bottom",
});

const app = createApp(App);
const head = createHead();

app.use(store);
app.use(router);
app.use(head);
app.mount("#app");
