<template>
  <div class="about">
    <!-- <h2 class="text-center title-hero-page">à propos 👨🏻‍💻</h2> -->
    <!-- show gopher juste au dessus du tableau -->
    <img
      :src="gopher"
      alt="gopher"
      height="107"
      width="100"
      style="margin-left: 30px"
      data-aos="fade"
      title="gopher"
    />
    <div class="content-background-dotted p-3 text-center pt-3 shadow">
      <div v-if="aboutContent && aboutContent.length > 0">
        <div class="rounded mx-auto p-2 about-container mt-2">
          <p
            v-for="item in aboutContent"
            :key="item.id"
            class="content-mono mx-auto"
            style="text-align: justify"
          >
            {{ item.field_334683 }}
          </p>
        </div>
        <div class="d-flex justify-content-center my-2">
          <img
            :src="stackFileUrl"
            alt="stack tech"
            class="stack-pic mb-1"
            height="100"
            width="100"
            title="stack tech"
          />
        </div>
        <div
          v-if="aboutContent2.length > 0"
          class="rounded mx-auto about-container"
        >
          <p
            v-for="item in aboutContent2"
            :key="item.id"
            class="content-mono mx-3 mt-4"
            style="text-align: justify"
          >
            <span v-if="item.field_337161">{{ item.field_337153 }}</span>
          </p>
        </div>
        <div v-else>
          <p class="text-center mt-5 text-muted">rien à voir ici ...</p>
        </div>
      </div>
      <div v-else class="smooth">
        <p class="text-light text-center mt-3">
          Les données sont en cours de chargement ...
        </p>
        <p class="text-light text-center">
          Si rien ne s'affiche actualiser la page 👍️
        </p>
        <div class="d-flex justify-content-center mt-3">
          <img :src="loadingFile" alt="loading" height="70" width="70" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import loadingFile from "../assets/images/loading.gif";
import gopher from "../assets/images/gopher.webp";

export default {
  name: "AboutComponent",
  data() {
    return {
      stackFileUrl: null,
      loadingFile,
      sf: null,
      gopher: gopher,
    };
  },
  computed: mapState({
    aboutContent: (state) => state.aboutContent,
    aboutContent2: (state) => state.aboutContent2,
  }),
  watch: {
    aboutContent: {
      handler() {
        if (this.aboutContent.length > 0) {
          this.stackFileUrl = this.aboutContent[1].field_334759[0].url;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.about-container {
  width: 60%;
}

.stack-pic {
  width: 60%;
  height: auto;
}

@media screen and (max-width: 600px) {
  .about-container {
    width: 100%;
  }

  .stack-pic {
    width: 110%;
  }
}
</style>
