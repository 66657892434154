<template>
  <div class="footer">
    <div class="mt-5 pt-3">
      <hr style="color: var(--foreground)" />
      <div class="row d-flex my-5 justify-content-between">
        <div class="col-md-4">
          <h2 class="title-second my-3">Who?</h2>
          <p class="text-muted-footer">
            Ce site rassemble actuellement des informations techniques et
            professionnelles à mon sujet. Il est en train d'être développé pour
            ajouter une partie "blog & actualités" ainsi qu'une section
            "ressources" avec des liens vers des sites et des outils pour les
            développeurs.
          </p>
        </div>
        <div class="col-md-4">
          <h2 class="title-second my-3">Contact</h2>
          <p class="text-muted-footer">
            Vous souhaitez me contacter pour poser des questions, partager vos
            commentaires, demander des conseils ou discuter d'un projet ? 👇️
          </p>
          <button
            class="btn btn-sm btn-pink"
            data-tally-open="mBMX7n"
            data-tally-layout="modal"
            data-tally-width="502"
            data-tally-emoji-text="🤘 "
            data-tally-emoji-animation="bounce"
            data-tally-auto-close="3000"
            style="font-size: 0.8rem; font-weight: bold; padding: 0.5rem 1rem"
          >
            Contactez moi
          </button>
        </div>

        <div class="col-md-3">
          <h2 class="title-second my-3">Follow</h2>
          <small>Vous pouvez me retouver par ici 👇️.</small>
          <div class="text-muted-footer d-flex mt-3">
            <ul class="p-0">
              <li>
                <a
                  class="link-style"
                  href="https://mozilla.social/@benoitpetit"
                  target="_blank"
                >
                  <i class="bi bi-mastodon"></i> Mastodon
                </a>
              </li>
              <li>
                <a
                  class="link-style"
                  href="https://t.me/benoitpetit"
                  target="_blank"
                >
                  <i class="bi bi-telegram"></i> Telegram
                </a>
              </li>
              <li>
                <a
                  class="link-style"
                  href="https://www.linkedin.com/in/benoit-petit-110"
                  target="_blank"
                >
                  <i class="bi bi-linkedin"></i> Linkedin
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  class="link-style"
                  href="https://github.com/benoitpetit"
                  target="_blank"
                >
                  <i class="bi bi-github"></i> Github
                </a>
              </li>
              <li>
                <a
                  class="link-style"
                  href="https://gitlab.com/petitbenoit"
                  target="_blank"
                >
                  <i class="bi bi-gitlab"></i> GitLab
                </a>
              </li>
              <li>
                <a
                  class="link-style"
                  href="https://www.npmjs.com/settings/codingben/packages"
                  target="_blank"
                >
                  <i class="bi bi-box2-heart-fill"></i> NPM Packages
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr />
      <div
        class="my-4 d-flex justify-content-center flex-column align-items-center"
      >
        <div
          class="d-flex justify-content-center flex-column align-items-center"
        >
          <small class="mt-3 mb-1 text-muted title-second">powered by</small>
          <img
            :src="powered"
            height="30"
            width="326"
            alt="powered logo"
            class="powered mt-1"
            title="powered logo"
          />
          <small class="text-muted mt-0"
            >code with ❤️ by
            <a
              href="https://piaille.fr/@codingben"
              class="link-style"
              target="_blank"
              >Benoit Petit</a
            ></small
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import powered from "../assets/images/powered.webp";

export default {
  name: "FooterComponent",
  setup() {
    const year = ref(new Date().getFullYear());

    return {
      year,
      powered,
    };
  },
};
</script>
<style scoped>
.text-muted-footer {
  font-size: 14px;
  color: #888888;
}

/* modifier les points de la list en coeur */
ul {
  list-style-type: none;
  padding: 0.2érem;
}

ul li {
  padding: 0.1rem;
}
</style>
